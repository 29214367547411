<template>
  <v-card class="teros-elevation" :color="tableColor">
    <v-card-title class="px-5 text-uppercase v-title-cadastro"> 
      CÁLCULO DO INCREMENTO DE PESQUISADORES DETALHADO 
    </v-card-title>
    <v-card-text>
      <div class="d-flex pt-0">
        <v-menu offset-y dense max-width="160px">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="table-v-action-button mr-2">
              <v-icon>mdi-file-export-outline</v-icon> Exportação
            </div>
          </template>
          <v-list>
            <v-list-item style="cursor: pointer" @click="handleExportCsv()">
              <v-list-item-title class="v-label-input">
                <v-icon left>mdi-file-delimited-outline</v-icon> Exportar CSV
              </v-list-item-title>
            </v-list-item>
            <v-list-item style="cursor: pointer" @click="handleExportXlsx()">
              <v-list-item-title class="v-label-input">
                <v-icon left>mdi-file-excel-outline</v-icon> Exportar XLSX
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-select
          style="max-width: 170px"
          class="mb-n1 mt-0 mr-3"
          prepend-icon="mdi-calendar"
          prefix="Ano Base:"
          :items="anosBase"
          v-model="anoBase"
          dense
          @change="doLoad()"
        ></v-select>
      </div>
    </v-card-text>
    <v-data-table
      data-app
      class="mb-6"
      :items="resumo"
      :headers="headerStyle(colunas.resumo)"
      :footer-props="{
        'items-per-page-options': [-1],
      }"
      hide-default-footer
    >
      <template v-slot:item="{ item, headers }">
        <tr class="table-v-tr" style="cursor: default">
          <td v-for="col in headers" v-bind:key="col.key">
            {{ item[col.value] }}
          </td>
        </tr>
      </template>
      <template v-slot:[`body.append`]>
        <tr class="table-v-tr" style="cursor: default">
          <th>Incentivo P&D</th>
          <th>{{ incentivo }}</th>
          <th></th>
        </tr>
      </template>
    </v-data-table>
    <template v-for="(item, idx) in colaboradores">
      <v-data-table
        class="mb-6"
        data-app
        :key="idx"
        :headers="
          headerStyle([
            ...colunas.colaboradores,
            { text: item.ano, value: 'fmedia' },
          ])
        "
        :items="item.colaboradores"
        :footer-props="{
          'items-per-page-options': [-1],
        }"
        hide-default-footer
      >
        <template v-slot:item="{ item, headers }">
          <tr class="table-v-tr" style="cursor: default">
            <td
              v-for="col in headers"
              v-bind:key="col.key"
              :class="{ 'font-weight-bold': col.value == 'fmedia' }"
            >
              {{ item[col.value] == 0 ? "" : item[col.value] }}
            </td>
          </tr>
        </template>
        <template v-slot:[`body.append`]="append">
          <tr class="table-v-tr" style="cursor: default">
            <th v-for="col in append.headers" v-bind:key="col.key">
              {{
                total[item.ano][col.value] == 0
                  ? ""
                  : total[item.ano][col.value]
              }}
            </th>
          </tr>
        </template>
      </v-data-table>
    </template>
  </v-card>
</template>

<script>
import _ from "lodash";
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(["clientId"]),
    resource: function () {
      return this.apiResource(
        `/v1/rh/clientes/${this.clientId}/incrementoDetalhado`
      );
    },
    resumo: function () {
      return this.relatorio.resumo.map((r) => {
        r.ano = `Média Pesquisadores ${r.ano}`;
        r.media = this.$options.filters.toDouble(r.media);
        return r;
      });
    },
    incentivo: function () {
      return this.relatorio.resultado[0]
        ? this.relatorio.resultado[0].incentivo
        : "";
    },
    colaboradores: function () {
      return _.chain(this.relatorio.colaboradores)
        .groupBy("fano")
        .map((value, key) => {
          var colaboradores = value.map((colaborador) => {
            var dtAdm = this.getDate(colaborador.admissao_emped);
            var dtDem = this.getDate(colaborador.demissao_emped);

            var ini = dtAdm.ano == colaborador.fano ? dtAdm.mes * 1 : 1;
            var fin = dtDem.ano == colaborador.fano ? dtDem.mes * 1 : 12;

            for (var i = 1; i <= 12; i++) {
              colaborador["m" + i] = 0;
            }
            for (var j = ini; j <= fin; j++) {
              colaborador["m" + j] = 1;
            }
            colaborador["media"] = colaborador["fmedia"] * 1;
            colaborador["fmedia"] = this.$options.filters.toDouble(
              colaborador["fmedia"]
            );

            return colaborador;
          });
          this.total[key] = { nome: "Total", fmedia: 0, media: 0 };
          for (var i = 1; i <= 12; i++) {
            this.total[key]["m" + i] = 0;
          }
          this.total[key] = _.reduce(
            colaboradores,
            (colaboradorA, colaboradorB) => {
              for (var i = 1; i <= 12; i++) {
                colaboradorA["m" + i] += colaboradorB["m" + i];
              }
              colaboradorA["media"] += colaboradorB["media"];
              return colaboradorA;
            },
            this.total[key]
          );
          this.total[key]["fmedia"] = this.$options.filters.toDouble(
            this.total[key]["media"]
          );

          //colaboradores.push(total);
          return {
            ano: key,
            colaboradores: colaboradores,
          };
        })
        .value();
    },
    relatorioParaExportacao: function () {
      const resumoHeader = this.colunas.resumo.map(({ text }) => text || "");
      const resumo = this.resumo.map((row) => {
        return this.colunas.resumo.map((col) => row[col.value]);
      });
      resumo.push(["Incentivo P&D", this.incentivo, ""]);

      const colaboradores = this.colaboradores
        .map((colab) => {
          const colaboradoresCols = [
            ...this.colunas.colaboradores,
            { text: colab.ano, value: "fmedia" },
          ];
          const header = colaboradoresCols.map(({ text }) => text);
          const body = colab.colaboradores.map((colaborador) => {
            return colaboradoresCols.map((col) => {
              return colaborador[col.value] || "";
            });
          });
          const totais = colaboradoresCols.map((col) => {
            return this.total[colab.ano][col.value] || "";
          });
          return [header, ...body, totais, []];
        })
        .reduce((prev, curr) => [...prev, ...curr], []);
      const exportacao = [resumoHeader, ...resumo, [], ...colaboradores];
      exportacao.pop();
      return exportacao;
    },
    anosBase: function () {
      var anoAtual = new Date().getFullYear();
      return [anoAtual, anoAtual - 1, anoAtual - 2, anoAtual - 3, anoAtual - 4];
    },
  },
  created: function () {
    this.anoBase = new Date().getFullYear();
    this.doLoad();
  },
  methods: {
    doLoad: function () {
      this.resource.get({query: `anoBase=${this.anoBase}`}).then((response) => {
        this.relatorio = response;
      });
    },
    getDate: function (date) {
      if (!date) {
        return { ano: null, mes: null, dia: null };
      }
      var arr = date.split("-");
      return { ano: arr[0], mes: arr[1], dia: arr[2] };
    },
    headerStyle: function (headers) {
      return headers.map((h) => {
        h.class = `vuetify-table-header${this.getClient().isClient ? "-cli" : ""}`;
        return h;
      });
    },
    handleExportCsv: function () {
      this.exportCsv(this.relatorioParaExportacao);
    },
    handleExportXlsx: function () {
      this.exportXlsx(this.relatorioParaExportacao);
    },
  },
  data: function () {
    return {
      total: {},
      relatorio: {
        colaboradores: [],
        resumo: [],
        resultado: [],
      },
      anoBase: 2020,
      colunas: {
        resumo: [
          { text: "", value: "ano" },
          { text: "Quantidade", value: "media" },
          { text: "Acrécimo", value: "acrescimo" },
        ],
        colaboradores: [
          { text: "Nome", value: "nome" },
          { text: "CPF", value: "cpf" },
          { text: "Jan.", value: "m1" },
          { text: "Fev.", value: "m2" },
          { text: "Mar.", value: "m3" },
          { text: "Abr.", value: "m4" },
          { text: "Mai.", value: "m5" },
          { text: "Jun.", value: "m6" },
          { text: "Jul.", value: "m7" },
          { text: "Ago.", value: "m8" },
          { text: "Set.", value: "m9" },
          { text: "Out.", value: "m10" },
          { text: "Nov.", value: "m11" },
          { text: "Dez.", value: "m12" },
        ],
      },
    };
  },
  watch: {
    clientId: function () {
      this.doLoad();
    },
  },
};
</script>